<template>
    <v-app>
        <v-container fluid>
            <div>
                <transition name="modal-fade">
                    <div class="modal-backdrop">
                        <section class="modal-body">
                            <div>
                                <v-card-title class="pa-3 pb-0">
                                    <p class="paragraph-font-size">Edytuj wpis działań następczych</p>
                                </v-card-title>
                                <br />
                                <v-checkbox v-model="localNote.showInReport"
                                            label="Pokazuj w rejestrze zgłoszeń"></v-checkbox>

                                <v-textarea v-model="localNote.text"
                                            solo
                                            auto-grow
                                            label="Treść..."></v-textarea>
                            </div>
                            <br /><br />
                            <v-row align="center" justify="space-around" class="row-padding">
                                <v-btn color="default" @click="close()" class="btn-width-default">Anuluj</v-btn>
                                <v-btn color="primary" @click="saveEditNote()" class="btn-width-save">Zapisz</v-btn>
                            </v-row>
                        </section>
                    </div>
                </transition>
            </div>
        </v-container>
    </v-app>
</template>

 <script>
     export default {
         name: 'EditNoteModal',
         props: {
             noteToEdit: {
                 type: Object,
                 required: true,
             },
         },
         data() {
             return {
                 localNote: { ...this.noteToEdit }, // Create a local copy of the note
             };
         },
         methods: {
             close() {
                 this.$emit('close');
             },
             saveEditNote() {
                 this.$emit("updateNotes", { ...this.localNote }); // Emit a copy of the local note
             },
         },
         watch: {
             noteToEdit: {
                 immediate: true,
                 handler(newNote) {
                     this.localNote = { ...newNote }; // Update localNote if noteToEdit changes
                 },
             },
         },
     };
 </script>

<style scoped>

    .row-padding {
        padding-bottom: 15px;
    }

    .btn-width-default {
        max-width: 100px;
    }

    .btn-width-save {
        max-width: 300px;
    }

    .paragraph-font-size {
        font-size: 20px;
    }

    .modalName {
        font-weight: bold;
        text-align: center;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .modal-body {
        position: center;
        padding: 20px 10px;
        background-color: white;
        max-width: 520px;
        min-width: 450px;
        max-height: calc(100vh - 10px);
        font-size: 14px;
    }

    input[type=button] {
        margin-left: 5px;
    }

    .slotBody {
        margin-right: auto;
        margin-left: auto;
        justify-content: center;
        align-items: center;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }
</style>