<template>
    <v-app>
        <v-container fluid id="login">
            <v-row no-gutters>
                <v-col cols="3" class="main-part d-none d-md-none d-lg-flex">
                    <div class="d-flex">
                        <v-img src="@/assets/sygnali_logo_450.png" contain class="img-logo-left-size"></v-img>
                        <span class="span-logo-color">PLATFORMA OCHRONY SYGNALISTÓW</span>
                    </div>
                </v-col>
                <v-col cols="12" lg="9" class="login-part d-flex align-center justify-center">
                    <v-row no-gutters class="align-start">
                        <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
                            <div class="login-wrapper pt-md-4 pt-0">
                                <v-tabs grow>
                                    <v-tabs-slider></v-tabs-slider>
                                    <v-tab :href="`#tab-login`">
                                        REJESTRACJA - KROK 2
                                    </v-tab>
                                    <v-tab-item :value="'tab-login'">
                                        <v-form>
                                            <v-container>
                                                <v-row class="flex-column">
                                                    <v-col cols="12" class="d-flex align-center my-8">
                                                    </v-col>
                                                    <v-form>
                                                        <v-col>
                                                            <v-text-field v-bind:value="email"
                                                                          label="Adres Email"
                                                                          required
                                                                          disabled></v-text-field>
                                                            <v-text-field :rules="nameRules"
                                                                          v-model="name"
                                                                          value=""
                                                                          label="Imię i Nazwisko"
                                                                          required></v-text-field>
                                                            <v-text-field v-model="password"
                                                                          :rules="passRules"
                                                                          type="password"
                                                                          label="Ustaw hasło"
                                                                          hint="Minimum 6 znaków"
                                                                          required></v-text-field>
                                                            <v-text-field :rules="nameRules"
                                                                          v-model="companyName"
                                                                          :error-messages="companyErrors"
                                                                          value=""
                                                                          :counter="200"
                                                                          @input="$v.companyName.$touch()"
                                                                          @blur="$v.companyName.$touch()"
                                                                          label="Nazwa firmy/instytucji"
                                                                          required></v-text-field>
                                                            <v-text-field :rules="nameRules"
                                                                          v-model="companyAddress"
                                                                          value=""
                                                                          label="Adres firmy/instytucji"
                                                                          required></v-text-field>
                                                            <v-text-field :rules="nameRules"
                                                                          v-model="companyZipCode"
                                                                          value=""
                                                                          label="Kod pocztowy"
                                                                          required></v-text-field>
                                                            <v-text-field :rules="nameRules"
                                                                          v-model="companyCity"
                                                                          value=""
                                                                          label="Miasto"
                                                                          required></v-text-field>
                                                        </v-col>
                                                        <v-col class="d-flex justify-space-between">
                                                            <v-btn class="text-capitalize"
                                                                   large
                                                                   :disabled="password.length === 0 || email.length === 0 || companyName.length == 0 || companyName.length > 200 || name.length == 0 || companyAddress.length == 0 || companyZipCode.length == 0 || companyCity.length == 0"
                                                                   color="primary"
                                                                   @click="activate">
                                                                Aktywuj konto
                                                            </v-btn>
                                                        </v-col>
                                                    </v-form>
                                                </v-row>
                                            </v-container>
                                        </v-form>
                                    </v-tab-item>
                                </v-tabs>
                            </div>
                        </v-col>
                        <!-- <v-col cols="12" class="d-flex justify-center">
                          <v-footer>
                            <div class="primary--text">© 2014-2020 <a href="https://flatlogic.com/" class="text-decoration-none">Flatlogic</a>, LLC. All rights reserved.</div>
                          </v-footer>
                        </v-col> -->
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
    import Service from '@/services/Service'
    import { validationMixin } from 'vuelidate'
    import { required, maxLength } from 'vuelidate/lib/validators'
  export default {
        name: 'Registration',
    mixins: [validationMixin],
        validations: {
            companyName: { required, maxLength: maxLength(200) },
    },
    computed: {
        companyErrors() {
            const errors = []
            if (!this.$v.companyName.$dirty) return errors
            !this.$v.companyName.maxLength && errors.push('Nazwa nie może mieć więcej niż 200 znaków')
            !this.$v.companyName.required && errors.push('Nazwa jest wymagana.')
            return errors
        },
    },
    data() {
      return {
        email: '',
        emailRules: [
          v => !!v || 'E-mail jest wymagany',
          v => /.+@.+/.test(v) || 'Niepoprawny E-mail',
        ],
        createFullName: '',
          createEmail: '',
          activationLink: '',
          createPassword: '',
          companyName: '',
          password: '',
          companyZipCode: '',
          companyCity: '',
          companyAddress: '',
          name: '',
          nameRules: [v => !!v || 'Pole jest wymagane'],
        passRules: [
          v => !!v || 'Hasło jest wymagane',
          v => v.length >= 6 || 'Minimum 6 znaków'
        ]
      }
    },
    created(){
        document.title = "Sygnali.pl";
    },
    async mounted() {
        try {
            this.email = (await Service.getEmailFromActivationLink(this.$route.params.email)).data.email
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            this.$router.push({ name: 'Error2' });
        }
    },
    methods: {
        async activate() {
            try {
                const user = {
                    email: this.email,
                    password: this.password,
                    companyName: this.companyName,
                    companyAddress: this.companyAddress,
                    companyZipCode: this.companyZipCode,
                    companyCity: this.companyCity,
                    name: this.name
                }

                const result = (await Service.activateAccount(user));

                if (result.status == 200) {
                    alert("Dziękujemy. Konto zostało aktywowane. Teraz możesz się zalogować.");
                    this.$router.push('/login');
                }
            } catch (e) {
                alert(e.response.status + " - " + e.response.data.toString());
                return;
                //this.$router.push({ name: 'Error' });
            }
      }
    },
  }

</script>

<style src="./Login.scss" lang="scss" />
