<template>
  <v-app>
    <v-container fluid class="error-page">
      <v-row class="logo-wrapper">      
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="8">
            <div class="card">
                <v-img src="@/assets/sygnali_logo_450_black.png" contain class="img-width-style"></v-img>
                <p class="error-text">Coś poszło nie tak. Wróć do strony głównej</p>
                <v-btn class="text-capitalize btn-bottom"
                       dark
                       x-large
                       :color="config.light.primary"
                       to="dashboard" >
                    Powrót
                </v-btn>
            </div>
        </v-col>
      </v-row>
  </v-container>
  </v-app>
</template>

<script>
import config from '@/config';

export default {
  name: 'Error',
  data(){
    return{
      config
    }
  }
};
</script>

<style src="./Error.scss" lang="scss"></style>
