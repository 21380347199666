<template>
    <v-app>
        <v-container fluid>
            <div>
                <transition name="modal-fade">
                    <div class="modal-backdrop">
                        <section class="modal-body" id="modalDescription">
                            <div id="settingsdata">
                                <div ref="pdf">
                                    <v-card-title class="pa-3 pb-0"><p class="card-font-title">Sygnali.pl</p></v-card-title>
                                    <br />
                                    <v-card-title class="pa-3 pb-0"><p>Dziękujemy! Twoje zgłoszenie zostało przyjęte.</p></v-card-title>
                                    <v-card-text class="pa-3 pb-0">Data zgłoszenia: <span class="font-bold">{{new Date().toLocaleDateString("pl-PL")}}, godz. {{new Date().toLocaleTimeString()}}</span></v-card-text>
                                    <v-card-text class="pa-3 pb-0">Adres skrzynki odbiorczej: <span class="font-bold"><a href="https://app.sygnali.pl/#/inboxlogin" target="_blank">https://app.sygnali.pl/#/inboxlogin</a></span></v-card-text>
                                    <v-card-text class="pa-3 pb-0">Identyfikator: <span class="font-bold">{{sygnal.identifier}}</span></v-card-text>
                                    <v-card-text class="pa-3 pb-0">Hasło: <span class="font-bold">{{sygnal.password}}</span></v-card-text>                              
                                    <br />
                                    <v-card-text class="pa-3 pb-0 card-justify">Zapisz identyfikator i hasło. Zaloguj się w <b>Skrzynce Odbiorczej Sygnalisty</b> powyższym identyfikatorem i hasłem. Znajdziesz tam status zgłoszenia oraz będziesz mógł/mogła prowadzić dalszą konwersację z odbiorcą zgłoszenia.</v-card-text>
                                    <br />
                                    <v-card-text class="pa-3 pb-0 card-justify card-font-size-min"><hr>MWC Sp. z o.o. @ Sygnali.pl - Platforma Ochrony Sygnalistów</v-card-text>
                                </div>
                                <br /><br />
                                <v-row align="center" justify="space-around" class="row-padding">
                                    <v-btn color="default" @click="close()" class="btn-width-default"> Zamknij</v-btn>
                                    <v-btn color="primary" @click="generatePdf()"  class="btn-width-save"> Pobierz potwierdzenie (pdf)</v-btn>
                                </v-row>
                            </div>
                        </section>
                    </div>
                </transition>
            </div>
        </v-container>
    </v-app>
</template>

<script>
    import pdfMake from 'pdfmake';
    import pdfFonts from 'pdfmake/build/vfs_fonts';
    import htmlToPdfmake from 'html-to-pdfmake';

    export default {
        name: 'SygnaliModal',
        props: {
            sygnal: Object
        },
        data() {
            return {
            }
        },
        methods: {
            close() {
                this.$emit('close');
            },
            generatePdf() {
                const pdfTable = this.$refs.pdf;
                var html = htmlToPdfmake(pdfTable.innerHTML);
                const documentDefinition = { content: html };
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake.createPdf(documentDefinition).download("zgloszenie" + new Date().toLocaleDateString("pl-PL") + ".pdf");
            }
        },
    };
</script>

<style scoped>
    .modalName {
        font-weight:bold;
        text-align: center;
    }

    .btn-width-default {
        max-width: 100px;
    }

    .btn-width-save {
        max-width: 300px;
    }

    .row-padding {
        padding-bottom: 15px;
    }

    .card-font-size-min {
        font-size: 0.8em;
    }

    .card-font-title {
        font-size: 20px;
    }

    .card-justify {
        text-align: justify;
    }

    .font-bold {
        font-weight: bold;
    }

    .modal-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .modal-body {
        position: center;
        padding: 20px 10px;
        background-color: white;
        max-width: 520px;
        min-width: 450px;
        max-height: calc(100vh - 10px);
        font-size: 14px;
    }

    input[type=button] {
    margin-left: 5px;
    }

    .slotBody {
    margin-right: auto;
    margin-left: auto;
    justify-content: center;
    align-items: center;
    }

    .modal-fade-enter,
    .modal-fade-leave-to {
        opacity: 0;
    }

    .modal-fade-enter-active,
    .modal-fade-leave-active {
        transition: opacity .5s ease;
    }
</style>