<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :temporary="$vuetify.breakpoint.smAndDown"
    :mini-variant-width="sidebarMinWidth"
    :class="{'drawer-mini': !DRAWER_STATE}">
    <v-list>
      <template v-for="(item, i) in items">
        <v-row
          v-if="item.heading"
          :key="item.heading"
          align="center">
          <v-col cols="6" class="py-5">
            <span
              class="text-body-1 subheader side-bar-padding"
              :class="(item.heading && DRAWER_STATE) ? 'show ' : 'hide'">
              {{ item.heading }}
              </span>
          </v-col>
          <v-col
            cols="6"
            class="text-center">
          </v-col>
        </v-row>
        <v-divider
          v-else-if="item.divider"
          :key="i"
          dark
          class="my-4"
        ></v-divider>
        <v-list-group
          :color="item.customerIsActive ? 'primary' : 'grey'"
          v-else-if="item.children && DRAWER_STATE"
          :key="item.title"
          v-model="item.model"
          append-icon="">
            <template v-slot:prependIcon>
              <v-icon size="28" :color="item.color && item.customerIsActive ? item.color : 'grey'">mdi-image-filter-none</v-icon>
            </template>
            <template v-slot:activator >
              <v-list-item-content :class="item.customerIsActive ? '' : 'disable-menu'">
                <v-list-item-title >
                    {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item
              :class="item.customerIsActive ? '' : 'disable-menu'"
              v-for="(child, i) in item.children"
              :key="i"
              :to="checkLink(child)"
              link>
                <v-list-item-action v-if="child.icon">
                  <v-icon size="" :color="item.color && item.customerIsActive ? item.color : 'grey'">{{ child.icon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title >
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list-group>
        <v-list-item
          :color="item.customerIsActive ? 'primary' : 'grey'"
          :class="item.customerIsActive ? '' : 'disable-menu'"
          v-else
          :key="item.text"
          :href="item.href ? item.href : null"
          :to="checkLink(item)"
          @click="goto(item)"
          link>
          <v-list-item-action>
            <v-icon size="28" :color="item.color && item.customerIsActive ? item.color : 'grey'">{{ item.icon }}</v-icon>
          </v-list-item-action>
            <v-list-item-content >
                <v-list-item-title link> {{ item.title }} </v-list-item-title>
            </v-list-item-content>
        </v-list-item>

      </template>
    </v-list>
    </v-navigation-drawer>
</template>

<script>
import {mapActions, mapState} from 'vuex'
import Service from '@/services/Service'
  export default {
    props: {
          source: String,
    },
    data(){
        return {
        items: [],
        sidebarWidth: 240,
        sidebarMinWidth: 96,
        linkToForm: '',
        messageCount: 0,
        main: {},
        customerIsActive: false,
        customerInternalSales: false,
      }
    },
    watch: {
        '$store.state.admin': async function () {
            this.customerIsActive = this.$store.state.admin.customerIsActive;
            this.customerInternalSales = this.$store.state.admin.customerInternalSales;
            await this.reloadItemsSitebar();
        }
    },
    computed: {
      ...mapState(['drawer']),
      DRAWER_STATE: {
        get() {
          return this.drawer
        },
        set(newValue) {
          if (newValue === this.drawer) return;
          this.TOGGLE_DRAWER();
        }
      }
    },
    async mounted() {
        try {
            await this.reloadItemsSitebar();
        } catch (e) {
            console.log(e)
        }
    },
    methods: {
        ...mapActions(['TOGGLE_DRAWER']),
        async goto(item) {
            if (item.title == "Pomoc") {
                window.open('https://pomoc.mwc.pl/sygnali/', '_blank').focus();
            }
            if (item.title == "Formularz" && item.customerIsActive) {
                try {
                    this.linkToForm = (await Service.getLinkToForm()).data.linkToForm;
                    let routeData = this.$router.resolve({ path: `/form/${this.linkToForm}` });
                    window.open(routeData.href, '_blank');
                } catch (e) {
                    console.log(e)
                }
            }
        },
        checkLink(item) {
            if (item.customerIsActive) {
                return item.link === '#' ? null : item.link
            }
            else {
                return null;
            }
        },
        async reloadItemsSitebar() {
            try {
                this.customerIsActive = this.$store.state.admin.customerIsActive;
                this.customerInternalSales = this.$store.state.admin.customerInternalSales;

                if (this.customerInternalSales === true) {
                    this.items = this.getMenuInternalSales();
                }
                else {
                    this.items = this.getMenu();
                }

            } catch (e) {
                console.log(e)
            }
        },
        getMenu() {
            var menu = [
                { title: 'Panel główny', icon: 'mdi-home', link: '/dashboard', customerIsActive: this.customerIsActive, color: 'primary' },
                {
                    title: 'Zgłoszenia', icon: 'mdi-cards-outline', link: '/new', customerIsActive: this.customerIsActive, color: 'primary', model: false,
                    children: [
                        { title: 'Nowe', icon: 'mdi-circle-small', link: '/new', customerIsActive: this.customerIsActive, color: 'primary' },
                        { title: 'Otwarte', icon: 'mdi-circle-small', link: '/open', customerIsActive: this.customerIsActive, color: 'primary' },
                        { title: 'Archiwalne', icon: 'mdi-circle-small', link: '/archive', customerIsActive: this.customerIsActive, color: 'primary' },
                        { title: 'Wszystkie', icon: 'mdi-circle-small', link: '/all', customerIsActive: this.customerIsActive, color: 'primary' },
                    ]
                },
                { title: 'Wiadomości', icon: 'mdi-message-text-outline', link: '/messages', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Użytkownicy', icon: 'mdi-account-multiple', link: '/users', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Kategorie', icon: 'mdi-clipboard-text', link: '/categories', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Rejestr zgłoszeń', icon: ' mdi-sticker', link: '/reports', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Ustawienia', icon: 'mdi-wrench', link: '/settings', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Log systemowy', icon: 'mdi-account-search', link: '/logs', customerIsActive: this.customerIsActive, color: 'primary' },
                {
                    title: 'Rozliczenia', icon: 'mdi-bank', link: '/subscription', customerIsActive: true, color: 'primary', model: false, children: [
                        { title: 'Subskrypcja', icon: 'mdi-cash-clock', link: '/subscription', customerIsActive: true, color: 'primary' },
                        { title: 'Zamówienia', icon: 'mdi-invoice-text-multiple-outline', link: '/orders', customerIsActive: true, color: 'primary' },
                    ]
                },
                { divider: true },
                { title: 'Formularz', icon: 'mdi-launch', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Pomoc', icon: 'mdi-help-circle-outline', customerIsActive: true, color: 'primary' },
            ];

            return menu;
        },
        getMenuInternalSales() {
            var internal = [
                { title: 'Panel główny', icon: 'mdi-home', link: '/dashboard', customerIsActive: this.customerIsActive, color: 'primary' },
                {
                    title: 'Zgłoszenia', icon: 'mdi-cards-outline', link: '/new', customerIsActive: this.customerIsActive, color: 'primary', model: false,
                    children: [
                        { title: 'Nowe', icon: 'mdi-circle-small', link: '/new', customerIsActive: this.customerIsActive, color: 'primary' },
                        { title: 'Otwarte', icon: 'mdi-circle-small', link: '/open', customerIsActive: this.customerIsActive, color: 'primary' },
                        { title: 'Archiwalne', icon: 'mdi-circle-small', link: '/archive', customerIsActive: this.customerIsActive, color: 'primary' },
                        { title: 'Wszystkie', icon: 'mdi-circle-small', link: '/all', customerIsActive: this.customerIsActive, color: 'primary' },
                    ]
                },
                { title: 'Wiadomości', icon: 'mdi-message-text-outline', link: '/messages', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Użytkownicy', icon: 'mdi-account-multiple', link: '/users', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Kategorie', icon: 'mdi-clipboard-text', link: '/categories', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Rejestr zgłoszeń', icon: ' mdi-sticker', link: '/reports', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Ustawienia', icon: 'mdi-wrench', link: '/settings', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Log systemowy', icon: 'mdi-account-search', link: '/logs', customerIsActive: this.customerIsActive, color: 'primary' },
                { divider: true },
                { title: 'Formularz', icon: 'mdi-launch', customerIsActive: this.customerIsActive, color: 'primary' },
                { title: 'Pomoc', icon: 'mdi-help-circle-outline', customerIsActive: true, color: 'primary' },
            ];

            return internal;
        },
    }
  }
</script>

<style src="./Sidebar.scss" lang="scss"/>
