import Vue from 'vue';
import App from './App.vue';
import router from './Routes';
import store from './store/index';
import vuetify from './plugins/vuetify';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "../src/assets/css/roboto.css";
import "../src/assets/css/materialdesignicons.min.css";

Vue.use(Toast);
Vue.config.productionTip = false;

fetch(window.location.href)
    .then(response => {
        const nonce = response.headers.get('X-CSP-Nonce');
        if (nonce) {
            window.CSP_NONCE = nonce;
        } else {
            console.error('Nonce not found in headers.');
        }
    })
    .catch(err => console.error('Error fetching nonce:', err))
    .finally(() => {
        vuetify.framework.theme.options.cspNonce = window.CSP_NONCE || '';

        new Vue({
            vuetify,
            router,
            store,
            render: h => h(App),
        }).$mount('#app');
    });


