<template>
  <v-app-bar
    class="main-header"
    height="64"
    fixed
    color='primary'
    dark>
    <v-btn icon class="mx-1" @click.stop="TOGGLE_DRAWER">
      <template v-if="DRAWER_STATE">
        <v-icon  class="icon-size">mdi-arrow-left</v-icon>
      </template>
      <template v-else>
        <v-icon class="icon-size">mdi-menu</v-icon>
      </template>
    </v-btn>
    <v-toolbar-title><v-img src="@/assets/sygnali_logo_450.png" contain class="logo-width"></v-img></v-toolbar-title>
    <v-spacer></v-spacer>
          <v-btn icon class="btn-margin-rigth" disabled>
      <v-icon>mdi-account</v-icon>
      <div>{{loginUser}}</div>
     </v-btn>
    
    <v-btn icon @click="logOut" class="btn-header-margin">
      <v-icon>mdi-logout</v-icon>
      <div>Wyloguj</div>
     </v-btn>
  </v-app-bar>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
    import config from '../../config';
    import Service from '@/services/Service'
  export default {
    name: 'Header',
    data: () => ({
      config,
      searchCollapse: true,
      notificationsBadge: true,
      messageBadge: true,
      loginUser: {}
    }),
    computed: {
      ...mapState(['drawer']),
      DRAWER_STATE :{
        get() {
          return this.drawer
        },
        },
    },
    async mounted() {
        try {
            var result = (await Service.getAdmin()).data;
            this.loginUser = result.name;
            this.$store.commit('changeAdminData', result);

        } catch (e) {
           console.log(e)
        }
    },
    methods: {
      ...mapActions([ 'TOGGLE_DRAWER' ]),
      async logOut() {
          try {
              var result = (await Service.logout());

              if (result.status == 200) {
                  localStorage.removeItem('csrfToken-Sygnali');
                  this.$router.push('/login');
              }

          } catch (e) {
              alert(e.response.status + " - " + e.response.data.toString());
          }
      },
    }
  };
</script>

<style src="./Header.scss" lang="scss"></style>
