<template>
  <v-container fluid>
      <div class="tables-basic">
          <h1 class="page-title mt-10 mb-6">Kategorie</h1>

          <ErrorSubscription :isCustomerActive="isCustomerActive()" />

          <v-row v-if="showGlobalListModal && isCustomerActive()">
              <v-col cols=12>
                  <v-card class="mb-1">
                      <v-card-title class="pa-5 pb-3">
                          <p>Kategorie zgłoszeń</p>
                          <v-spacer></v-spacer>
                          <v-tooltip top v-if="isMainAdmin && haveInternalCategory == false">
                              <template v-slot:activator="{ on }">
                                  <v-btn class="mgr-r-10" color="green" text @click="createInternalCategory()" v-on="on">
                                      wewnętrzne
                                      <v-icon>mdi-file-document-edit-outline</v-icon>
                                  </v-btn>
                              </template>
                              <span>Dodaj domyślną kategorię 'Zgłoszenia wewnętrzne'</span>
                          </v-tooltip>

                          <v-tooltip top v-if="isMainAdmin && haveExternalCategory == false">
                              <template v-slot:activator="{ on }">
                                  <v-btn color="pink" text @click="createExternalCategory()" v-on="on">
                                      zewnętrzne
                                      <v-icon>mdi-file-document-edit-outline</v-icon>
                                  </v-btn>
                              </template>
                              <span>Dodaj domyślną kategorię 'Zgłoszenia zewnętrzne'</span>
                          </v-tooltip>

                          <v-spacer></v-spacer>
                          <v-btn v-if="isMainAdmin" @click="addNewCategory()">Dodaj</v-btn>
                      </v-card-title>
                      <v-simple-table>
                          <template v-slot:default>
                              <thead>
                                  <tr>
                                      <th class="text-left pa-6">NAZWA</th>
                                      <th class="text-left">WIDOCZNA</th>
                                      <th class="text-left">STATUS</th>
                                      <th class="text-left">DOŁĄCZANIE PLIKÓW</th>
                                      <th class="text-left">ANONIMOWA</th>
                                      <th class="text-left">ZAŁĄCZNIKI</th>
                                      <th class="text-left">UŻYTKOWNICY</th>
                                      <th v-if="isMainAdmin" class="text-left"></th>
                                      <th class="text-left"></th>
                                      <th class="text-left"></th>
                                      <th class="text-left"></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="item in categories" :key="item.id">
                                      <td class="pa-6">{{ item.name }}</td>
                                      <td>{{ item.visible ? 'TAK' : 'NIE' }}</td>
                                      <td>{{ formatTypeStatus(item.status) }}</td>
                                      <td>{{ item.files ? 'TAK' : 'NIE'}}</td>
                                      <td>{{ item.anonymous ? 'TAK' : 'NIE' }}</td>
                                      <td>{{ item.attachmentsCount }}</td>
                                      <td><div v-for="i in item.users" :key="i.userId">{{i.userName}}</div></td>
                                      <td v-if="isMainAdmin">
                                          <v-tooltip top>
                                              <template v-slot:activator="{ on }">
                                                  <v-btn text @click="updateUsersForCategories(item.users, item.id, item.name)" v-on="on">
                                                      <v-icon color="blue">mdi-account-multiple-plus</v-icon>
                                                  </v-btn>
                                              </template>
                                              <span>UŻYTKOWNICY</span>
                                          </v-tooltip>
                                      </td>
                                      <td v-if="isMainAdmin">
                                          <v-tooltip top>
                                              <template v-slot:activator="{ on }">
                                                  <v-btn text @click="updateCategory(item)" v-on="on">
                                                      <v-icon color="green">mdi-circle-edit-outline</v-icon>
                                                  </v-btn>
                                              </template>
                                              <span>EDYTUJ</span>
                                          </v-tooltip>
                                      </td>
                                      <td v-if="isMainAdmin">
                                          <v-tooltip top>
                                              <template v-slot:activator="{ on }">
                                                  <v-btn text @click="updateAttachments(item.id)" v-on="on">
                                                      <v-icon>mdi-attachment</v-icon>
                                                  </v-btn>
                                              </template>
                                              <span>ZAŁĄCZNIKI</span>
                                          </v-tooltip>
                                      </td>
                                      <td v-if="isMainAdmin && categories.length > 1">
                                          <v-tooltip top>
                                              <template v-slot:activator="{ on }">
                                                  <v-btn text @click="deleteCategory(item.id)" v-on="on">
                                                      <v-icon color="red">mdi-trash-can</v-icon>
                                                  </v-btn>
                                              </template>
                                              <span>USUŃ</span>
                                          </v-tooltip>
                                      </td>
                                      <td v-else></td>
                                  </tr>
                              </tbody>
                          </template>
                      </v-simple-table>
                  </v-card>
              </v-col>
          </v-row>
          <v-row v-if="showCategoryModal && isCustomerActive()">
              <v-col cols="12">
                  <v-card class="pa-3 pb-3">
                      <v-card-title class="pa-6 pb-1">
                          <p v-if="name.length == 0">Dodawanie kategorii</p>
                          <p v-else>Edycja kategorii</p>
                          <v-spacer></v-spacer>
                          <v-btn v-if="isMainAdmin" @click="closeModal()">Zamknij</v-btn>
                      </v-card-title>
                      <form class="pa-10 pb-10">
                          <v-text-field v-model="name"
                                        :error-messages="nameErrors"
                                        :counter="50"
                                        label="Nazwa kategorii"
                                        required
                                        @input="$v.name.$touch()"
                                        @blur="$v.name.$touch()"></v-text-field>
                          <v-checkbox v-model="visible"
                                      label="Kategoria widoczna w formularzu zgłoszenia?"
                                      @change="$v.visible.$touch()"
                                      @blur="$v.visible.$touch()" :disabled="categories.length == 1 && categoryTempId > 0"></v-checkbox>
                          <v-checkbox v-model="status"
                                      label="Czy kategoria ma być aktywna?"
                                      @change="$v.status.$touch()"
                                      @blur="$v.status.$touch()" :disabled="categories.length == 1 && categoryTempId > 0"></v-checkbox>
                          <v-checkbox v-model="files"
                                      label="Możliwość dołączania plików do zgłoszenia przez sygnalistę?"
                                      @change="$v.files.$touch()"
                                      @blur="$v.files.$touch()"></v-checkbox>
                          <v-checkbox v-model="anonymous"
                                      label="Zgłoszenie anonimowe? (w przypadku odznaczenia sygnalista będzie poszony o podanie Imienia, Nazwiska i danych kontaktowych)"
                                      @change="$v.anonymous.$touch()"
                                      @blur="$v.anonymous.$touch()"></v-checkbox>
                          <div v-if="elementsListForCategory.length > 0" class="cat-list">
                              <v-card-title class="pa-4 pb-4"><p>Lista elementów formularza</p></v-card-title>
                              <v-card-text class="pa-2 pb-3" v-for="item in elementsListForCategory" :key="item.label">
                                  {{item.textType}} - {{item.label}} - Wymagane: {{item.required ? 'TAK' : 'NIE'}}
                                  <v-btn icon @click="editElementFromCategory(item)">
                                      <v-icon color="green">mdi-circle-edit-outline</v-icon>
                                  </v-btn>
                                  <v-btn icon @click="deleteElementFromCategory(item)">
                                      <v-icon color="red">mdi-trash-can</v-icon>
                                  </v-btn>
                              </v-card-text>
                          </div>

                          <v-card-title class="pa-4 pb-4">
                              <p>Tworzenie formularza zgłoszenia</p>
                              <v-btn @click="createElement()" class="mgr-l-20" color="blue">Stwórz element</v-btn>
                          </v-card-title>
                          <div v-if="showCreateElement">
                              <v-select v-model="select"
                                        :items="items"
                                        :error-messages="selectErrors"
                                        label="Typ elementu"
                                        required
                                        :disabled="temporaryElem != null"
                                        @change="$v.select.$touch()"
                                        @blur="$v.select.$touch()"></v-select>
                              
                              <div v-if="select.includes('1')" class="cat-list">
                                  <v-text-field v-model="textareaElem"
                                                :error-messages="textareaErrors"
                                                :counter="500"
                                                label="Nazwa obszaru tekstowego"
                                                required
                                                @input="$v.textareaElem.$touch()"
                                                @blur="$v.textareaElem.$touch()"></v-text-field>
                                  <v-checkbox v-model="textAreaIsRequired"
                                              label="Czy pole jest wymagane?"
                                              @change="$v.textAreaIsRequired.$touch()"
                                              @blur="$v.textAreaIsRequired.$touch()"></v-checkbox>
                                  <br />
                                  <v-btn @click="createTextAreaElement()" color="blue" :disabled="textareaElem.length == 0 || textareaElem.length > 501">Zatwierdź element</v-btn>
                                  <v-btn @click="closeShowField()" class="mgr-l-10">Anuluj</v-btn>
                              </div>
                              <div v-if="select.includes('2')" class="cat-list">
                                  <v-text-field v-model="inputElem"
                                                :error-messages="inputErrors"
                                                :counter="500"
                                                label="Nazwa pola tekstowego"
                                                required
                                                @input="$v.inputElem.$touch()"
                                                @blur="$v.inputElem.$touch()"></v-text-field>
                                  <v-checkbox v-model="inputIsRequired"
                                              label="Czy pole jest wymagane?"
                                              @change="$v.inputIsRequired.$touch()"
                                              @blur="$v.inputIsRequired.$touch()"></v-checkbox>
                                  <br />
                                  <v-btn @click="createInputElement()" color="blue" :disabled="inputElem.length == 0 || inputElem.length > 501">Zatwierdź element</v-btn>
                                  <v-btn @click="closeShowField()" class="mgr-l-10">Anuluj</v-btn>
                              </div>
                              <div v-if="select.includes('3')" class="cat-list">
                                  <v-text-field v-model="checkboxElem"
                                                :error-messages="checkboxssErrors"
                                                :counter="500"
                                                label="Nazwa pola wyboru (checkbox)"
                                                required
                                                @input="$v.checkboxElem.$touch()"
                                                @blur="$v.checkboxElem.$touch()"></v-text-field>
                                  <p class="font-s-10">*Jeżeli chcesz umieścić klikalny link w nazwie pola checkbox zastosuj składnię html. Przykład: <pre>&lt;a href=&quot;https://sygnali.pl&quot; target=&quot;blank&quot;&gt;Link do strony Sygnali&lt;/a&gt;</pre></p>
                                  <v-checkbox v-model="checkboxIsRequired"
                                              label="Czy zaznaczenie tego checkboxsa w formularzu jest wymagane?"
                                              @change="$v.checkboxIsRequired.$touch()"
                                              @blur="$v.checkboxIsRequired.$touch()"></v-checkbox>
                                  <br />
                                  <v-btn @click="createCheckboxsElement()" color="blue" :disabled="checkboxElem.length == 0 || checkboxElem.length > 501">Zatwierdź element</v-btn>
                                  <v-btn @click="closeShowField()" class="mgr-l-10">Anuluj</v-btn>
                              </div>
                              <div v-if="select.includes('4')" class="cat-list">
                                  <v-text-field v-model="selectListElem"
                                                :error-messages="selectListErrors"
                                                :counter="500"
                                                label="Nazwa listy wyboru (select list)"
                                                required
                                                @input="$v.selectListElem.$touch()"
                                                @blur="$v.selectListElem.$touch()"></v-text-field>
                                  <v-text-field v-model="selectListElemToSelekt"
                                                :error-messages="selectListToSelektErrors"
                                                :counter="900"
                                                label="Opcje wyboru rozdzielone przecinkiem np. Jestem zadowolony,Nie jestem zadowolony,Nie mam zdania"
                                                required
                                                @input="$v.selectListElemToSelekt.$touch()"
                                                @blur="$v.selectListElemToSelekt.$touch()"></v-text-field>
                                  <v-checkbox v-model="selectListIsRequired"
                                              label="Czy wybranie opcji w formularzu jest wymagane?"
                                              @change="$v.selectListIsRequired.$touch()"
                                              @blur="$v.selectListIsRequired.$touch()"></v-checkbox>
                                  <br />
                                  <v-btn @click="createSelectListElement()" color="blue" :disabled="selectListElem.length == 0 || selectListElem.length > 501 || selectListElemToSelekt.length == 0 || selectListElemToSelekt.length > 901">Zatwierdź element</v-btn>
                                  <v-btn @click="closeShowField()" class="mgr-l-10">Anuluj</v-btn>
                              </div>
                          </div>
                          <br />
                          <br />
                          <v-btn class="mr-4"
                                 @click="submit" color="green" :disabled="name.length == 0 || name.length > 50 || elementsListForCategory.length <= 0">
                              Zatwierdź kategorię zgłoszenia
                          </v-btn>
                          <v-btn @click="clear">
                              Wyczyść wszystkie pola
                          </v-btn>
                      </form>
                  </v-card>

              </v-col>
          </v-row>
          <v-row v-if="showUsersCategoryModal && isCustomerActive()">
              <v-col cols="12">
                  <v-card class="pa-3 pb-3">
                      <v-card-title class="pa-6 pb-1">
                          <p>Użytkownicy kategorii: {{categoryNameTemp}}</p>
                          <v-spacer></v-spacer>
                          <v-btn v-if="isMainAdmin" @click="closeCategoryUsersModalModal()" class="mgr-l-20">Zamknij</v-btn>
                      </v-card-title>
                      <form class="pa-6 pb-6">
                          <v-col cols="12">
                              <v-list shaped>
                                  <v-list-item-group v-model="selectedUsers" multiple>
                                      <div v-for="(item, i) in users" :key="item.userId">
                                          <v-divider v-if="!item"
                                                     :key="`divider-${i}`"></v-divider>
                                          <v-list-item v-else
                                                       :key="`item-${i}`"
                                                       :value="item"
                                                       active-class="deep-blue--text text--accent-4">
                                              <template v-slot:default="{ active }">
                                                  <v-list-item-content>
                                                      <v-list-item-title>{{i+1}}. {{item.userName}}</v-list-item-title>
                                                  </v-list-item-content>
                                                  <v-list-item-action>
                                                      <v-checkbox :input-value="active" color="deep-blue accent-4"></v-checkbox>
                                                  </v-list-item-action>
                                              </template>
                                          </v-list-item>
                                          <v-checkbox v-model="item.canResponse"
                                                      label="Czy może odpowiadać na zgłoszenia?"
                                                      @click="changeSelectedItemStatus(item)"
                                                      :disabled="isInSelected(item)"></v-checkbox>
                                      </div>
                                  </v-list-item-group>
                              </v-list>
                          </v-col>

                          <br />
                          <v-btn class="mr-4"
                                 @click="submitUsersList" color="green">
                              Zatwierdź użytkowników
                          </v-btn>
                          <v-btn @click="clearUsersList">
                              Wyczyść pola
                          </v-btn>
                      </form>
                  </v-card>

              </v-col>
          </v-row>
          <v-row v-if="showUpdateAttachments && isCustomerActive()">
              <v-col cols="12">
                  <v-card class="pa-3 pb-3">
                      <v-card-title class="pa-6 pb-1">
                          <p>Załączniki do kategorii</p>
                          <v-btn @click="addFile()" class="mgr-l-20" color=""><v-icon class="elem-color-blue">mdi-attachment</v-icon>Dodaj</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn @click="closeModalAttachment()" color="">Zamknij</v-btn>
                      </v-card-title>
                      <form class="pa-10 pb-10">
                          <div v-if="attachments != null && attachments.length > 0">
                              <v-row>
                                  <v-col cols="6">
                                      <ul class="zalaczniki" v-for="item in attachments" :key="item.id">
                                          <li>
                                              {{item.name}} <v-btn icon @click="showEditFileNameElem(item)" :disabled="showFileNameInputField">
                                                  <v-icon class="elem-color-green">mdi-circle-edit-outline</v-icon>
                                              </v-btn>
                                              <v-btn icon @click="deleteFile(item)" :disabled="showFileNameInputField">
                                                  <v-icon class="elem-color-red">mdi-trash-can</v-icon>
                                              </v-btn>
                                          </li>
                                      </ul>
                                  </v-col>
                                  <v-col cols="4">
                                      <div v-if="showFileNameInputField">

                                          <v-text-field v-model="newFileName"
                                                        label="Nowa nazwa załącznika"
                                                        class="elem-max-width">
                                          </v-text-field>
                                          <v-btn icon @click="editFileName()" :disabled="newFileName.length <= 3">
                                              <v-icon class="elem-color-green">mdi-check</v-icon>
                                          </v-btn>
                                          <v-btn icon @click="cencelEditFileName()">
                                              <v-icon class="elem-color-red">mdi-window-close</v-icon>
                                          </v-btn>

                                      </div>
                                  </v-col>
                              </v-row>
                          </div>
                          <div v-else>
                              <ul class="zalaczniki">
                                  <li>Brak załączników...</li>
                              </ul>
                          </div>
                          <label class="elem-font-size">*PDF, DOC, DOCX, JPG, PNG, XLS, XLSX, CSV, MP3 - dodane załączniki będą możliwe do pobrania w formularzu zgłoszenia tej kategorii</label>
                          <br />
                      </form>
                  </v-card>
              </v-col>
          </v-row>
      </div>
  </v-container>
  
</template>

<script>
    import Service from '@/services/Service'
    import { validationMixin } from 'vuelidate'
    import { required, maxLength, email } from 'vuelidate/lib/validators'
    import ErrorSubscription from '@/components/Modals/ErrorInfoSubscription.vue'
export default {
        name: 'Categories',
        components: {
            ErrorSubscription
        },
        mixins: [validationMixin],
        validations: {
            name: { required, maxLength: maxLength(50) },
            textareaElem: { required, maxLength: maxLength(500) },
            checkboxElem: { required, maxLength: maxLength(500) },
            selectListElem: { required, maxLength: maxLength(500) },
            selectListElemToSelekt: { required, maxLength: maxLength(900) },
            inputElem: { required, maxLength: maxLength(500) },
            email: { required, email },
            select: { required },
            visible: {
                checked(val) {
                    return val
                },
            },
            status: {
                checked(val) {
                    return val
                },
            },
            files: {
                checked(val) {
                    return val
                },
            },
            anonymous: {
                checked(val) {
                    return val
                },
            },
            textAreaIsRequired: {
                checked(val) {
                    return val
                },
            },
            inputIsRequired: {
                checked(val) {
                    return val
                },
            },
            checkboxIsRequired: {
                checked(val) {
                    return val
                },
            },
            selectListIsRequired: {
                checked(val) {
                    return val
                },
            },
        },
  data() {
    return {
        categories: [],
        showCategoryModal: false,
        main: [],
        isMainAdmin: false,
        name: '',
        email: '',
        select: '',
        items: [
            '1 - Obszar tekstowy (textarea)',
            '2 - Pole tekstowe (input)',
            '3 - Pole wyboru (checkbox)',
            '4 - Lista wyboru (select list)',
        ],
        visible: false,
        status: false,
        files: false,
        anonymous: true,
        showCreateElement: false,
        textareaElem: '',
        textAreaIsRequired: false,
        elementsListForCategory: [],
        inputElem: '',
        inputIsRequired: false,
        checkboxElem: '',
        checkboxIsRequired: false,
        selectListElem: '',
        selectListIsRequired: false,
        selectListElemToSelekt: '',
        categoryTempId: 0,
        showUsersCategoryModal: false,
        users: [],
        selectedUsers: [],
        categoryNameTemp: '',
        categoryIdTemp: 0,
        attachments: [],
        showUpdateAttachments: false,
        tempCategoryIdForAttachments: 0,
        showFileNameInputField: false,
        newFileName: '',
        tempFileElem: {},
        showGlobalListModal: true,
        temporaryElem: null,
        haveInternalCategory: false,
        haveExternalCategory: false,
    }
},
computed: {
    selectErrors() {
        const errors = []
        if (!this.$v.select.$dirty) return errors
        !this.$v.select.required && errors.push('Pole wymagane')
        return errors
    },
    nameErrors() {
        const errors = []
        if (!this.$v.name.$dirty) return errors
        !this.$v.name.maxLength && errors.push('Nazwa nie może mieć więcej niż 50 znaków')
        !this.$v.name.required && errors.push('Nazwa jest wymagana.')
        return errors
    },
    selectListToSelektErrors() {
        const errors = []
        if (!this.$v.selectListElemToSelekt.$dirty) return errors
        !this.$v.selectListElemToSelekt.maxLength && errors.push('Opcje wyboru nie mogą mieć więcej niż 900 znaków')
        !this.$v.selectListElemToSelekt.required && errors.push('Opcje wyboru są wymagane.')
        return errors
    },
    selectListErrors() {
        const errors = []
        if (!this.$v.selectListElem.$dirty) return errors
        !this.$v.selectListElem.maxLength && errors.push('Nazwa nie może mieć więcej niż 500 znaków')
        !this.$v.selectListElem.required && errors.push('Nazwa jest wymagana.')
        return errors
    },
    checkboxssErrors() {
        const errors = []
        if (!this.$v.checkboxElem.$dirty) return errors
        !this.$v.checkboxElem.maxLength && errors.push('Nazwa nie może mieć więcej niż 500 znaków')
        !this.$v.checkboxElem.required && errors.push('Nazwa jest wymagana.')
        return errors
    },
    textareaErrors() {
        const errors = []
        if (!this.$v.textareaElem.$dirty) return errors
        !this.$v.textareaElem.maxLength && errors.push('Nazwa nie może mieć więcej niż 500 znaków')
        !this.$v.textareaElem.required && errors.push('Nazwa jest wymagana.')
        return errors
    },
    inputErrors() {
        const errors = []
        if (!this.$v.inputElem.$dirty) return errors
        !this.$v.inputElem.maxLength && errors.push('Nazwa nie może mieć więcej niż 500 znaków')
        !this.$v.inputElem.required && errors.push('Nazwa jest wymagana.')
        return errors
    },
},
async mounted() {
    try {

        if (this.isCustomerActive()) {
            this.main = (await Service.getCategoriesForCategoriesPanel()).data,
            this.categories = this.main.categories,
            this.isMainAdmin = this.main.isMainAdmin,
            this.checkCategioriesInternalExternal()
        }

    } catch (e) {
        if (e.response.status == 401) {
            this.$router.push({ name: 'Login' })
        }
    }
  },
methods: {
    checkCategioriesInternalExternal() {
        const existsInternal = this.categories.some(obj => obj.name.toLowerCase() === "zgłoszenia wewnętrzne");
        if (existsInternal)
            this.haveInternalCategory = true;

        const existsExternal = this.categories.some(obj => obj.name.toLowerCase() === "zgłoszenia zewnętrzne");
        if (existsExternal)
            this.haveExternalCategory = true;

    },
    isCustomerActive() {
        return this.$store.state.admin.customerIsActive;
    },
    isInSelected(item) {
        if (this.selectedUsers.length > 0) {
            var index = this.selectedUsers.findIndex(x => x.userId == item.userId);
            if (index != -1) {
                return false;
            } else {
                var p2 = this.users.findIndex(x => x.userId == item.userId);
                if (p2 != -1)
                    this.users[p2].canResponse = false;
                return true;
            }
        }
        else {
            var p = this.users.findIndex(x => x.userId == item.userId);
            if (p != -1)
                this.users[p].canResponse = false;
            return true;
        }
    },
    async updateAttachments(item) {
        try {
            var result = (await Service.getFilesForCategory(item)).data;
            if (Object.keys(result).length > 0) {
                this.attachments = result;
                this.tempCategoryIdForAttachments = item;
                
                this.showUpdateAttachments = true;
                this.showGlobalListModal = false;
                
            } else {
                this.tempCategoryIdForAttachments = item;
                this.attachments = [];
                
                this.showUpdateAttachments = true;
                this.showGlobalListModal = false;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    closeModalAttachment() {
        this.showUpdateAttachments = false;
        this.tempCategoryIdForAttachments = 0;
        this.showGlobalListModal = true;
    },
    changeSelectedItemStatus(item) {
        if (this.selectedUsers.length > 0) {
            var index = this.selectedUsers.findIndex(x => x.userId == item.userId);
            if (index != -1) {
                if (this.selectedUsers[index].canResponse) {
                    this.selectedUsers[index].canResponse = true;
                }
                else {
                    this.selectedUsers[index].canResponse = false;
                }
            }
        }
    },
    async createInternalCategory() {
        try {
            var comu = 'Czy na pewno utworzyć kategorię: Zgłoszenia wewnętrzne?';

            if (confirm(comu)) {

                var result = (await Service.createInternalReportsCategory()).data;
                if (result == true) {
                    this.main = (await Service.getCategoriesForCategoriesPanel()).data;
                    this.categories = this.main.categories;
                    this.isMainAdmin = this.main.isMainAdmin;
                    this.checkCategioriesInternalExternal();
                    alert("Sukces! Poprawnie dodano kategorię: Zgłoszenia wewnętrzne.");
                }
            }
            else {
                return;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    async createExternalCategory() {
        try {
            var comu = 'Czy na pewno utworzyć kategorię: Zgłoszenia zewnętrzne?';

            if (confirm(comu)) {

                var result = (await Service.createExternalReportsCategory()).data;
                if (result == true) {
                    this.main = (await Service.getCategoriesForCategoriesPanel()).data;
                    this.categories = this.main.categories;
                    this.isMainAdmin = this.main.isMainAdmin;
                    this.checkCategioriesInternalExternal();
                    alert("Sukces! Poprawnie dodano kategorię: Zgłoszenia zewnętrzne.");
                }
            }
            else {
                return;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    async submitUsersList() {
        try {

            var temp = {
                categoryId: this.categoryIdTemp,
                users: this.selectedUsers
            };

            var result = (await Service.saveSelectedUsersToCategory(temp));
            if (result.status == 200) {

                var index = this.categories.findIndex(x => x.id == this.categoryIdTemp);
                if (index != -1) {
                   this.categories[index].users = [];

                    for (var i = 0; i < this.selectedUsers.length; i++) {
                        this.categories[index].users.push(this.selectedUsers[i]);
                        this.selectedUsers[i].canResponse = false;
                    }
                    this.users = [];
                    this.selectedUsers = [];
                    this.categoryNameTemp = '';
                    this.categoryIdTemp = 0;
                    this.showUsersCategoryModal = false;
                    this.showGlobalListModal = true;
                }
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    clearUsersList() {
        for (var i = 0; i < this.selectedUsers.length; i++) {
            this.selectedUsers[i].canResponse = false;
        }
        this.selectedUsers = [];
        for (var j = 0; j < this.users.length; j++) {
            this.users[j].canResponse = false;
        }
    },
    async updateUsersForCategories(user, id, name) {
        try {
            var result = (await Service.getCategoryUsersForEdit(id)).data;
            if (Object.keys(result).length > 0) {
                this.users = result;
                this.selectedUsers = [];
                for (var i = 0; i < user.length; i++) {
                    var index = this.users.findIndex(x => x.userId == user[i].userId);
                    if (index != -1) {
                        this.selectedUsers.push(this.users[index]);
                    }
                }
                this.categoryNameTemp = name;
                this.categoryIdTemp = id;
                this.showUsersCategoryModal = true;
                this.showGlobalListModal = false;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    closeCategoryUsersModalModal() {
        for (var i = 0; i < this.selectedUsers.length; i++) {
            this.selectedUsers[i].canResponse = false;
        }
        this.users = [];
        this.selectedUsers = [];
        this.categoryNameTemp = '';
        this.categoryIdTemp = 0;
        this.showUsersCategoryModal = false;
        this.showGlobalListModal = true;
    },
    deleteElementFromCategory(item) {
        if (this.elementsListForCategory.length > 0) {
            for (var i = 0; i < this.elementsListForCategory.length; i++) {
                if (this.elementsListForCategory[i].label == item.label) {
                    var index = this.elementsListForCategory.indexOf(this.elementsListForCategory[i])
                    this.elementsListForCategory.splice(index, 1);
                }
            }
        }
    },
    editElementFromCategory(item) {
        if (this.elementsListForCategory.length > 0) {

            if (item.id == 0) {
                // new elem not in database
                var selected2 = this.elementsListForCategory.find(x => x.label == item.label && x.textType == item.textType && x.type == item.type && x.categoryId == item.categoryId);
                this.select = selected2.type + " - " + selected2.textType;
                this.switchElems(item);
            }
            else {
                // is in database
                var selected = this.elementsListForCategory.find(x => x.id == item.id);
                this.select = selected.type + " - " + selected.textType;
                this.switchElems(item);
            }

            this.createElement();
        }
    },
    switchElems(selected) {
        this.temporaryElem = selected;
        switch (selected.type) {
            case 1:
                this.textareaElem = selected.label;
                this.textAreaIsRequired = selected.required;
                break;
            case 2:
                this.inputElem = selected.label;
                this.inputIsRequired = selected.required;
                break;
            case 3:
                this.checkboxElem = selected.label;
                this.checkboxIsRequired = selected.required;
                break;
            case 4:
                this.selectListElem = selected.label.split("|")[0];
                this.selectListElemToSelekt = selected.label.split("|")[1];
                this.selectListIsRequired = selected.required;
                break
            default:
                break;
        }
    },
    createCheckboxsElement() {
        if (this.checkboxElem.length > 0 || this.checkboxElem.length < 501) {
            if (this.elementsListForCategory == null)
                this.elementsListForCategory = [];

            if (this.temporaryElem != null) {
                // update
                if (this.temporaryElem.id == 0) {
                    var selected2 = this.elementsListForCategory.find(x => x.label == this.temporaryElem.label && x.textType == this.temporaryElem.textType && x.type == this.temporaryElem.type && x.categoryId == this.categoryTempId);
                    selected2.required = this.checkboxIsRequired;
                    selected2.label = this.checkboxElem;
                }
                else {
                    var selected = this.elementsListForCategory.find(x => x.id == this.temporaryElem.id);
                    selected.required = this.checkboxIsRequired;
                    selected.label = this.checkboxElem;
                }
            }
            else {
                //add new
                var tempElem = {
                    type: 3,
                    required: this.checkboxIsRequired,
                    label: this.checkboxElem,
                    textType: "Pole wyboru (checkbox)",
                    categoryId: this.categoryTempId,
                    order: null,
                    id: 0
                };
                this.elementsListForCategory.push(tempElem);
            }
            
            this.checkboxElem = '';
            this.checkboxIsRequired = false;
            this.select = '';
            this.showCreateElement = false;
            this.temporaryElem = null;
        }
        else {
            alert("Błąd z formularzem")
            return;
        }
    },
    createSelectListElement() {
        if (this.selectListElem.length > 0 || this.selectListElem.length < 501 || this.selectListElemToSelekt.length > 0 || this.selectListElemToSelekt.length < 901) {
            if (this.elementsListForCategory == null)
                this.elementsListForCategory = [];

            if (this.temporaryElem != null) {
                // update
                if (this.temporaryElem.id == 0) {
                    var selected2 = this.elementsListForCategory.find(x => x.label == this.temporaryElem.label && x.textType == this.temporaryElem.textType && x.type == this.temporaryElem.type && x.categoryId == this.categoryTempId);
                    selected2.required = this.selectListIsRequired;
                    selected2.label = this.selectListElem + "|" + this.selectListElemToSelekt;
                }
                else {
                    var selected = this.elementsListForCategory.find(x => x.id == this.temporaryElem.id);
                    selected.required = this.selectListIsRequired;
                    selected.label = this.selectListElem + "|" + this.selectListElemToSelekt;
                }
            }
            else {
                //add new
                var tempElem = {
                    type: 4,
                    required: this.selectListIsRequired,
                    label: this.selectListElem + "|" + this.selectListElemToSelekt,
                    textType: "Lista wyboru (select list)",
                    categoryId: this.categoryTempId,
                    order: null,
                    id: 0
                };
                this.elementsListForCategory.push(tempElem);
            }

            this.selectListElem = '';
            this.selectListElemToSelekt = '';
            this.selectListIsRequired = false;
            this.select = '';
            this.showCreateElement = false;
            this.temporaryElem = null;
        }
        else {
            alert("Błąd z formularzem")
            return;
        }
    },
    createInputElement() {
        if (this.inputElem.length > 0 || this.inputElem.length < 501) {
            if (this.elementsListForCategory == null)
                this.elementsListForCategory = [];

            if (this.temporaryElem != null) {
                // update
                if (this.temporaryElem.id == 0) {
                    var selected2 = this.elementsListForCategory.find(x => x.label == this.temporaryElem.label && x.textType == this.temporaryElem.textType && x.type == this.temporaryElem.type && x.categoryId == this.categoryTempId);
                    selected2.required = this.inputIsRequired;
                    selected2.label = this.inputElem;
                }
                else {
                    var selected = this.elementsListForCategory.find(x => x.id == this.temporaryElem.id);
                    selected.required = this.inputIsRequired;
                    selected.label = this.inputElem;
                }
            }
            else {
                //add new
                var tempElem = {
                    type: 2,
                    required: this.inputIsRequired,
                    label: this.inputElem,
                    textType: "Pole tekstowe (input)",
                    categoryId: this.categoryTempId,
                    order: null,
                    id: 0
                };
                this.elementsListForCategory.push(tempElem);
            }

            this.inputElem = '';
            this.inputIsRequired = false;
            this.select = '';
            this.showCreateElement = false;
            this.temporaryElem = null;
        }
        else {
            alert("Błąd z formularzem")
            return;
        }
    },
    closeShowField() {
        this.select = '';
        this.showCreateElement = false;
        this.inputElem = '';
        this.inputIsRequired = false;
        this.textareaElem = '';
        this.textAreaIsRequired = false;
        this.selectListElem = '';
        this.selectListElemToSelekt = '';
        this.selectListIsRequired = false;
        this.checkboxElem = '';
        this.checkboxIsRequired = false;
        this.temporaryElem = null;
    },
    createTextAreaElement() {
        if (this.textareaElem.length > 0 || this.textareaElem.length < 501) {
            if (this.elementsListForCategory == null)
                this.elementsListForCategory = [];

            if (this.temporaryElem != null) {
                // update
                if (this.temporaryElem.id == 0) {
                    var selected2 = this.elementsListForCategory.find(x => x.label == this.temporaryElem.label && x.textType == this.temporaryElem.textType && x.type == this.temporaryElem.type && x.categoryId == this.categoryTempId);
                    selected2.required = this.textAreaIsRequired;
                    selected2.label = this.textareaElem;
                }
                else {
                    var selected = this.elementsListForCategory.find(x => x.id == this.temporaryElem.id);
                    selected.required = this.textAreaIsRequired;
                    selected.label = this.textareaElem;
                }
            }
            else {
                //add new
                var tempElem = {
                    type: 1,
                    required: this.textAreaIsRequired,
                    label: this.textareaElem,
                    textType: "Obszar tekstowy (textarea)",
                    categoryId: this.categoryTempId,
                    order: null,
                    id: 0
                };
                this.elementsListForCategory.push(tempElem);
            }

            this.textareaElem = '';
            this.textAreaIsRequired = false;
            this.select = '';
            this.showCreateElement = false;
            this.temporaryElem = null;
        }
        else {
            alert("Błąd z formularzem")
            return;
        }
    },
    createElement() {
        this.showCreateElement = true;
    },
    async submit() {
        try {
            var edit = '';
            if (this.categoryTempId > 0)
                edit = 'edytować';
            else
                edit = 'dodać nową';

            var comu = 'Czy na pewno ' + edit + ' kategorię?';

            if (confirm(comu)) {

                var temp = {
                    id: this.categoryTempId,
                    name: this.name,
                    visible: this.visible,
                    files: this.files,
                    anonymous: this.anonymous,
                    categoriesFields: this.elementsListForCategory,
                };

                if (this.status)
                    temp.status = 2;
                else
                    temp.status = 1;

                var result = (await Service.addNewCategory(temp)).data;
                if (Object.keys(result).length > 0) {
                    var index = this.categories.findIndex(x => x.id == result.id);
                    if (index != -1) {
                        this.categories[index].id = result.id;
                        this.categories[index].name = result.name;
                        this.categories[index].visible = result.visible;
                        this.categories[index].status = result.status;
                        this.categories[index].files = result.files;
                        this.categories[index].anonymous = result.anonymous;
                    } else {
                        result.users = [];
                        result.attachmentsCount = 0;
                        this.categories.push(result);
                    }
                    this.closeModal();
                }
            }
            else {
                return;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    clear() {
        this.name = ''
        this.select = ''
        this.checkbox = false
        this.visible = false
        this.status = false
        this.files = false
        this.anonymous = true
        this.showCreateElement = false
        this.textareaElem = ''
        this.textAreaIsRequired = false
        this.elementsListForCategory = []
        this.inputElem = ''
        this.inputIsRequired = false
        this.checkboxElem = ''
        this.checkboxIsRequired = false
        this.selectListElem = ''
        this.selectListIsRequired = false
        this.selectListElemToSelekt = ''
        this.categoryTempId = 0;
    },
    closeModal(){
        this.showCategoryModal = false
        this.name = ''
        this.select = ''
        this.checkbox = false
        this.visible = false
        this.status = false
        this.files = false
        this.anonymous = true
        this.showCreateElement = false
        this.textareaElem = ''
        this.textAreaIsRequired = false
        this.elementsListForCategory = []
        this.inputElem = ''
        this.inputIsRequired = false
        this.checkboxElem = ''
        this.checkboxIsRequired = false
        this.selectListElem = ''
        this.selectListIsRequired = false
        this.selectListElemToSelekt = ''
        this.categoryTempId = 0;
        this.showGlobalListModal = true;
    },
    formatTypeStatus(type) {
        if (type == 0)
            return 'Zablokowana'
        if (type == 1)
            return 'Nieaktywna'
        if (type == 2)
            return 'Aktywna'
    },
    addNewCategory() {
        this.showCategoryModal = true;
        this.showGlobalListModal = false;
    },
      async deleteCategory(value) {
        try {
            if (confirm('Na pewno usunąc kategorię?')) {

                var result = (await Service.removeCategory(value));
                if (result.status == 200) {
                    var index = this.categories.findIndex(x => x.id == value);
                    if (index != -1) {
                        this.categories.splice(index, 1);
                    }
                }
            }
            else {
                return;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    async updateCategory(item) {
        try {
            var result = (await Service.getCategoryForEdit(item)).data;
            if (Object.keys(result).length > 0) {
                this.categoryTempId = result.id;
                this.name = result.name;
                this.visible = result.visible;

                if (result.status == 2)
                    this.status = true;
                else
                    this.status = false;

                this.files = result.files;
                this.anonymous = result.anonymous;
                this.elementsListForCategory = [];
                this.elementsListForCategory = result.categoriesFields;

                for (var i = 0; i < this.elementsListForCategory.length; i++) {
                    if (this.elementsListForCategory[i].type == 1) {
                        this.elementsListForCategory[i].textType = "Obszar tekstowy (textarea)";
                    }
                    if (this.elementsListForCategory[i].type == 2) {
                        this.elementsListForCategory[i].textType = "Pole tekstowe (input)";
                    }
                    if (this.elementsListForCategory[i].type == 3) {
                        this.elementsListForCategory[i].textType = "Pole wyboru (checkbox)";
                    }
                    if (this.elementsListForCategory[i].type == 4) {
                        this.elementsListForCategory[i].textType = "Lista wyboru (select list)";
                    }
                }

                this.showCategoryModal = true;
                this.showGlobalListModal = false;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString())
            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    adminUpdateData(value){
        if (this.categories == null)
            this.categories = [];
      
        var index = this.categories.findIndex(x => x.id == value.id);
      if (index != -1){
          this.categories[index] = value;
        }
      else{
          this.categories.push(value);
      }
    },
    addFile() {
        let input = document.createElement('input');
        input.type = 'file';
        input.accept = '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf,.png,.jpg,.jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, .csv, audio/mpeg';
        input.multiple = false;
        input.click();
        input.addEventListener('change', (event) => {
            if (event.target.files[0].size <= 30000000) {
                if (this.checkMimeType(event.target.files[0].type)) {
                    this.saveElement(this.tempCategoryIdForAttachments, event.target.files);
                }
                else {
                    alert("Nieobsługiwany typ pliku. Pliki obsługiwane to: DOC,DOCX,PDF,JPG,JPEG,PNG,XLS,XLSX,CSV,MP3")
                    return;
                }
            } else {
                alert("Zbyt duży rozmiar pliku! Suma załączników nie może przekraczać 30MB");
                return;
            }
        });
    },
    checkMimeType(type) {
        if (type != "application/pdf" && type != "image/jpeg" && type != "image/jpg" &&
            type != "image/png" && type != "application/vnd.openxmlformats-officedocument.wordprocessingml.document" &&
            type != "text/csv" &&
            type != "audio/mpeg" &&
            type != "application/vnd.ms-excel" &&
            type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
            type != "text/plain") {
            return false;
        } else {
            return true;
        }
    },
    async deleteFile(item) {
        try {
            var result = (await Service.removeFileForCategories(parseInt(this.tempCategoryIdForAttachments), parseInt(item.id)));
            if (result.status == 200) {
                const index = this.attachments.findIndex(x => x.id == item.id);
                if (index != -1) {
                    this.attachments.splice(index, 1);
                    const ic = this.categories.findIndex(x => x.id == this.tempCategoryIdForAttachments);
                    if (ic != -1) {
                        this.categories[ic].attachmentsCount -= 1;
                    }
                }
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString());

            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    showEditFileNameElem(item) {
        this.newFileName = item.name.split(".")[0];
        this.tempFileElem = item;
        this.showFileNameInputField = true;
    },
    cencelEditFileName() {
        this.newFileName = '';
        this.tempFileElem = {};
        this.showFileNameInputField = false;
    },
    async editFileName() {
        try {
            var result = (await Service.renameFileForCategories(parseInt(this.tempCategoryIdForAttachments), parseInt(this.tempFileElem.id), this.newFileName));
            if (result.status == 200) {
                const index = this.attachments.findIndex(x => x.id == this.tempFileElem.id);
                if (index != -1) {
                    this.attachments[index].name = this.newFileName;
                }
                this.newFileName = '';
                this.tempFileElem = {};
                this.showFileNameInputField = false;
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString());

            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
    async saveElement(id, files) {
        try {
            let formData = new FormData();
            for (var i = 0; i < files.length; i++) {
                let file = files[i];
                formData.append('files[' + i + ']', file);
            }

            var result = (await Service.saveFileForCategoriesByAdmin(id, formData)).data;
            if (Object.keys(result).length > 0) {
                for (var p = 0; p < result.length; p++) {
                    var temp = {
                        name: result[p].name,
                        id: result[p].id
                    }
                    this.attachments.push(temp);

                    const ic = this.categories.findIndex(x => x.id == this.tempCategoryIdForAttachments);
                    if (ic != -1) {
                        this.categories[ic].attachmentsCount += 1;
                    }
                }
            }
        } catch (e) {
            alert(e.response.status + " - " + e.response.data.toString());

            if (e.response.status == 401)
                this.$router.push({ name: 'Login' })
        }
    },
  }
}

</script>

<style src="./Basic.scss" lang="scss"></style>
