import Api from '@/services/Api'

export default {
    // auth
    activateAccount(req) {
        return Api().post('auth/activateAccount', req)
    },
    resetPassForAdmin(req) {
        return Api().post('auth/resetPassForAdmin', req)
    },
    passwordReset(req) {
        return Api().post('auth/resetPassword', req)
    },
    register(req) {
        return Api().post('auth/register', req)
    },
    loginUser(req) {
        return Api().post('auth/login', req)
    },
    loginUserBySms(req) {
        return Api().post('auth/loginUserBySms', req)
    },
    changePasswordForAdmin(req) {
        return Api().post(`auth/changePasswordForAdmin`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getEmailFromActivationLink(id) {
        return Api().get(`auth/getEmailFromActivationLink/${id}`)
    },
    getEmailFromResetLink(id) {
        return Api().get(`auth/getEmailFromResetLink/${id}`)
    },
    logout() {
        return Api().post('auth/logout');
    },
    getAdmin() {
        return Api().get(`auth/getAdmin`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    updateTkAfterTransaction(data) {
        return Api().post(`auth/updateTk`, data, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },

    // categories
    getCategoriesForCategoriesPanel() {
        return Api().get('categories/getCategoriesForCategoriesPanel', {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getCategoryForEdit(req) {
        return Api().post(`categories/getCategoryForEdit`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getFilesForCategory(id) {
        return Api().get(`categories/getFilesForCategory/${id}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getCategoryUsersForEdit(id) {
        return Api().get(`categories/getCategoryUsersForEdit/${id}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    saveSelectedUsersToCategory(req) {
        return Api().post(`categories/saveSelectedUsersToCategory`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    addNewCategory(req) {
        return Api().post(`categories/addNewCategory`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    renameFileForCategories(catid, id, name) {
        return Api().post(`categories/renameFileForCategories/${catid}/${id}/${name}`, {}, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    removeFileForCategories(catid, id) {
        return Api().post(`categories/removeFileForCategories/${catid}/${id}`, {}, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    createExternalReportsCategory() {
        return Api().post('categories/createExternalReportsCategory', {}, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    createInternalReportsCategory() {
        return Api().post('categories/createInternalReportsCategory', {}, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getCategoriesList() {
        return Api().get('categories/getCategoriesList', {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },

    // supscryption
    getCustomerDataSubscryption() {
        return Api().get('subscription/getCustomerDataSubscryption', {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    checkCustomerData() {
        return Api().get('subscription/checkCustomerData', {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getFormOrderData() {
        return Api().get('subscription/getFormOrderData', {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getPackages() {
        return Api().get('subscription/getPackages', {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    checkDiscountCode(data) {
        return Api().post(`main/checkDiscountCode`, data, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },

    // main
    getSygnaliPublicKey() {
        return Api().get(`main/getSygnaliPublicKey`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getIp() {
        return Api().get(`main/getIp`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getNewSignals(count, page) {
        return Api().get(`main/getNewSignals/${count}/${page}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getLogs(lg) {
        return Api().get(`main/getLogs/${lg}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getLogsForAdmin(req) {
        return Api().post(`main/getLogsForAdmin`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getLogsForSpecyficSignal(id) {
        return Api().get(`main/getLogsForSpecyficSignal/${id}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    changeSygnalTypesData(req) {
        return Api().post(`main/changeSygnalTypesData`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getUsersForAdmin() {
        return Api().get('main/getUsersForAdmin', {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    downloadReportToFile(data) {
        return Api().post(`main/downloadReportToFile`, data, {
            responseType: 'arraybuffer',
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    changeCustomerDataForAdmin(req) {
        return Api().post(`main/changeCustomerDataForAdmin`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getMessagesOpenSignals(count, page) {
        return Api().get(`main/getMessagesOpenSignals/${count}/${page}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getAllSignals(count, page, catId) {
        return Api().get(`main/getAllSignals/${count}/${page}/${catId}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getCustomerData() {
        return Api().get('main/getCustomerData', {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getSpecificSignal(id) {
        return Api().get(`main/getSpecificSignal/${id}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getOpenSignals(count, page) {
        return Api().get(`main/getOpenSignals/${count}/${page}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getUsersForUsersPanel() {
        return Api().get('main/getUsersForUsersPanel', {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    moveSygnalToNewCategory(req) {
        return Api().post(`main/moveSygnalToNewCategory`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    removeUser(id) {
        return Api().post(`main/removeUser/${id}`, {}, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    removeCategory(id) {
        return Api().post(`main/removeCategory/${id}`, {}, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getArchiveSignals(count, page) {
        return Api().get(`main/getArchiveSignals/${count}/${page}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    addToArchive(req) {
        return Api().post(`main/addToArchive`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    deleteSignal(req) {
        return Api().post(`main/deleteSignal`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    sendReply(req) {
        return Api().post('main/sendReply', req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getLinkToForm() {
        return Api().get(`main/getLinkToForm`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    sendSignal(req) {
        return Api().post('main/sendSignal', req)
    },
    addNotesForSignal(req) {
        return Api().post(`main/addNotesForSignal`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    updateNotesForSignal(data) {
        return Api().post(`main/updateNotesForSignal`, data, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    addPropertyDamageInfoForSignal(req) {
        return Api().post(`main/addPropertyDamageInfoForSignal`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    updatePropertyDamageInfoForSignal(data) {
        return Api().post(`main/updatePropertyDamageInfoForSignal`, data, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    showMessages(req) {
        return Api().post(`main/showMessages`, req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    showNotesForSignal(id) {
        return Api().get(`main/showNotesForSignal/${id}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    showPropertyDamageInfoForSignal(id) {
        return Api().get(`main/showPropertyDamageInfoForSignal/${id}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    saveDomainAlias(data) {
        return Api().post(`main/saveDomainAlias`, data, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    deleteAliasByAdmin(data) {
        return Api().post(`main/deleteAliasByAdmin`, data, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getAdminName() {
        return Api().get(`main/getAdminName`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    allMessages(count) {
        return Api().get(`main/getallMessages/${count}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getAllSignalsForType(count, type, page) {
        return Api().get(`main/getAllSignalsForType/${count}/${type}/${page}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getDashboardSignalsForType(count, type, page) {
        return Api().get(`main/getDashboardSignalsForType/${count}/${type}/${page}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    main() {
        return Api().get('main/panel', {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    addOrUpdateUser(req) {
        return Api().post('main/addOrUpdateUser', req, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    savePrivateFileForSignalByAdmin(id, req) {
        return Api().post(`main/savePrivateFileForSignalByAdmin/${id}`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    saveFileForSignalByAdmin(id, req) {
        return Api().post(`main/saveFileForSignalByAdmin/${id}`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    saveFileForCategoriesByAdmin(id, req) {
        return Api().post(`main/saveFileForCategoriesByAdmin/${id}`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    saveCustomerLogoByAdmin(req) {
        return Api().post(`main/saveCustomerLogoByAdmin`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    downloadPrivateFile(req) {
        return Api().post(`main/downloadPrivateFile`, req, {
            responseType: 'arraybuffer',
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    downloadFile(req) {
        return Api().post(`main/downloadFile`, req, {
            responseType: 'arraybuffer',
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    removePrivateFile(id, fileId) {
        return Api().post(`main/removePrivateFile/${id}/${fileId}`, {}, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    removeFile(voteId, meetingId, fileId) {
        return Api().post(`main/removeFile/${voteId}/${meetingId}/${fileId}`)
    },
    removeCustomerLogoByAdmin() {
        return Api().post(`main/removeCustomerLogoByAdmin`, {}, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    

    // payment
    createOrderSubscription(data) {
        return Api().post(`payment/createOrderSubscription`, data, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getOrderList() {
        return Api().get(`payment/getOrderList`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    getOrderInfo(token) {
        return Api().get(`payment/order/${token}`)
    },
    setNoPaymentStatus(token) {
        return Api().get(`payment/order-cancel/${token}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },
    //generateInvoiceForOrder(data) {
    //    return Api().post(`payment/generateInvoiceForOrder`, data)
    //},
    getInvoiceOrderForClient(id) {
        return Api().get(`payment/getInvoiceOrderForClient/${id}`, {
            headers: {
                'X-CSRF-TOKEN-Sygnali': localStorage.getItem('csrfToken-Sygnali')
            }
        })
    },

    // inbox
    getSygnaliPublicKeyInbox(req) {
        return Api().post(`inbox/getSygnaliPublicKeyInbox`, req)
    },
    getSygnaliPublicKeyForm(id) {
        return Api().get(`inbox/getSygnaliPublicKeyForm/${id}`)
    },
    getSpecificSignalForInbox(req) {
        return Api().post(`inbox/getSpecificSignalForInbox`, req)
    },
    getFieldsForCategory(req) {
        return Api().post(`inbox/getFieldsForCategory`, req)
    },
    showMessagesForInbox(req) {
        return Api().post(`inbox/showMessagesForInbox`, req)
    },
    sendReplyForInbox(req) {
        return Api().post('inbox/sendReplyForInbox', req)
    },
    getNewToken(req) {
        return Api().post(`inbox/getNewToken`, req)
    },
    getSygnaliForm(id) {
        return Api().get(`inbox/getSygnaliForm/${id}`)
    },
    loginToInbox(req) {
        return Api().post('inbox/loginToInbox', req)
    },
    loginToInboxByPhone(req) {
        return Api().post('inbox/loginToInboxByPhone', req)
    },
    logoutInbox(req) {
        return Api().post('inbox/logoutInbox', req)
    },
    saveFileForSignal(domain, req) {
        return Api().post(`inbox/saveFileForSignal/${domain}`, req, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
    },
    saveFileForSignalInbox(req) {
        return Api().post(`inbox/saveFileForSignalInbox`, req, {
            headers: {
            'Content-Type': 'multipart/form-data',
            }
        })
    },
    downloadAttachment(req) {
        return Api().post(`inbox/downloadAttachment`, req, { responseType: 'arraybuffer' })
    },
    downloadFileForInbox(req) {
        return Api().post(`inbox/downloadFileForInbox`, req, { responseType: 'arraybuffer' })
    },
    
}
