<template>
  <v-app>
    <v-container fluid id="login">
      <v-row no-gutters>
        <v-col cols="3" class="main-part d-none d-md-none d-lg-flex">
            <div class="d-flex">
                <v-img src="@/assets/sygnali_logo_450.png" containclass="img-logo-left-size"></v-img>
                <span class="span-logo-color">PLATFORMA OCHRONY SYGNALISTÓW</span><br /><br />
                <v-card-text><p class="inbox-text-left">SKRZYNKA ODBIORCZA SYGNALISTY</p></v-card-text>
            </div>
        </v-col>
        <v-col cols="12" lg="9" class="login-part d-flex align-center justify-center">
          <v-row no-gutters class="align-start">
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper pt-md-4 pt-0">
                <v-tabs grow>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab :href="`#tab-login`">
                    LOGOWANIE
                  </v-tab>
                  <v-tab-item :value="'tab-login'" >
                    <v-form>
                      <v-container>
                        <v-row class="flex-column">
                          <v-col cols="12" class="d-flex align-center my-8">
                          </v-col>
                          <v-form>
                              <v-col>
                                  <v-text-field v-model="identifier"
                                                value=""
                                                :rules="idRules"
                                                label="Identyfikator zgłoszenia telefonicznego"
                                                autocomplete="off"
                                                required></v-text-field>
                                  <v-text-field v-model="phoneNumber"
                                                @keypress="onlyNumber"
                                                :rules="passRules"
                                                label="Numer telefonu"
                                                hint="9 cyfr bez spacji"
                                                required></v-text-field>
                              </v-col>
                            <v-col class="d-flex justify-space-between">
                              <v-btn
                                  class="text-capitalize"
                                  large
                                     autocomplete ="off"
                                  :disabled="phoneNumber.length !== 9 || identifier.length === 0"
                                  color="primary"
                                  @click="login"
                              >
                                Zaloguj</v-btn>
                            </v-col>
                          </v-form>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-tab-item>
                </v-tabs>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
    import Service from '@/services/Service'
  export default {
    name: 'InboxPhoneLogin',
    data() {
      return {
        identifier: '',
        idRules: [
          v => !!v || 'Identyfikator jest wymagany',
        ],
        phoneNumber: '',
        passRules: [
          v => !!v || 'Numer telefonu jest wymagany',
          v => v.length == 9 || '9 cyfr bez spacji'
        ]
      }
    },
    created(){
        document.title = "Sygnali.pl";
    },
    methods: {
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57)) {
                $event.preventDefault();
            }
        },
        async login() {
            try {
                const user = {
                    identifier: this.identifier,
                    phone: this.phoneNumber.toString()
                }
                const result = (await Service.loginToInboxByPhone(user)).data;
                if (Object.keys(result).length > 0) {
                    this.$store.commit('changeGuid', result.token);
                    this.$router.push({ name: 'Inbox', params: { identifier: result.identifier } });
                }
            } catch (e) {
                alert(e.response.status + " - " + e.response.data.toString());
                return;
            }
      }
    }
  }

</script>

<style src="../Login/Login.scss" lang="scss"/>
